import React from 'react';
import './CarouselSelectBox.css';

const CarouselSelectBox = props => {
	var className = "CarouselSelectBox";
	if (props.active) { className += " active"};
	return (
		<div className={className} onClick={props.onClick} data-key={props.boxkey} >
			<div className="mainText" onClick={props.onClick} data-key={props.boxkey}>{props.mainText}</div>
			<div className="secondaryText" onClick={props.onClick} data-key={props.boxkey}>{props.secondaryText}</div>
			<div className="subText" onClick={props.onClick} data-key={props.boxkey}>{props.subText}</div>
		</div>
	)
};

export default CarouselSelectBox;