import React, {Component} from 'react';
import $ from "jquery";
import './CarouselSelect.css';
import {ReactComponent as RightArrow} from './right-arrow.svg';
import {ReactComponent as LeftArrow} from './left-arrow.svg';

import CarouselSelectBox from '../CarouselSelectBox';

class CarouselSelect extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			position: 0,
			touchStartX: 0,
			translateX: 0,
			visibleItems: 3
		}
	}

	onSelectItem = (e) => {
		this.props.onSelectItem(e);
	}

	onTouchStart = (e) => {
		const element = $(e.target).closest('.CarouselSelectBox');
		if (element.css("transform")) {
			const transform = parseInt(element.css("transform").split(", ")[4]);
			this.setState({touchStartX: e.targetTouches[0].clientX, translateX: transform});
		}
	}

	onTouchMove = (e) => {
		const dx = e.changedTouches[0].clientX - this.state.touchStartX;
		const elements = $(e.target).closest('.boxes-wrapper').children(".CarouselSelectBox");
		const elementWidth = elements.outerWidth() + parseInt(elements.css("margin-right").replace("px","")) + parseInt(elements.css("margin-left").replace("px",""));
		const max = elements.length*-1*elementWidth + this.state.visibleItems * elementWidth;
		var newX = dx + this.state.translateX;
		console.log(newX);
		if (newX > 0) {
			newX = 0
		}
		if (newX < max) {
			newX = max;
		}
		
		elements.css("transform","matrix(1, 0, 0, 1, "+newX+", 0)");
	}

	render() {
		const boxes = this.props.itemList.map((item) => 
			<CarouselSelectBox onClick={this.onSelectItem} key={item.key} boxkey={item.key} active={item.isActive} mainText={item.mainText} secondaryText={item.secondaryText} subText={item.subText} />
		);

		return (
			<div className="carouselSelect">
				<LeftArrow className="left-arrow" onClick={this.props.onClickLeft} />
				<div onTouchStart={this.onTouchStart} onTouchMove={this.onTouchMove} className="boxes-wrapper" data-carouselposition={"" + this.props.position}>
					{boxes}
				</div>
				<RightArrow className="right-arrow" onClick={this.props.onClickRight} />
			</div>
		)
	}
}


export default CarouselSelect;