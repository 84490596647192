import React, {Component} from 'react';
import $ from 'jquery';
import Scheduler from './Components/Scheduler'
import StickyButton from './Components/StickyButton'
import './App.css';

class App extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			modalActive: false,
			buttonActive: false
		}

		if ($("#root").data("stickybutton") === true) {
			this.state.buttonActive = true;
		}
	}

	toggleModal = (e) => {
		if (this.state.modalActive) {
			this.setState({modalActive: false});
		} else {
			this.setState({modalActive: true});
		}
	}

	render() {
		var stickybutton;
		if (this.state.buttonActive) {
			stickybutton = <>
							<StickyButton onClick={this.toggleModal} label="Schedule Your Tour Now" />
						      <div className={this.state.modalActive ? "modal-wrapper active" : "modal-wrapper"}>
						      	<div className="modal">
						      		<div className="exit-icon" onClick={this.toggleModal}><svg onClick={this.toggleModal} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" className="svg-inline--fa fa-times fa-w-11 fa-3x"><path fill="#FFFFFF" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg></div>
						      		<Scheduler onClickStartLooking={this.toggleModal} />
						      	</div>
						      </div>
						    </>;
		}

		return (
			<div className="App">
		      <header className="App-header">
		        <Scheduler />
		      </header>
		      {stickybutton}
		    </div>
		)
	}
}

export default App;
