import React, {Component} from 'react';
import axios from 'axios';
import $ from 'jquery';
import FormTitle from '../FormTitle';
import TextInput from '../TextInput';
import CarouselSelect from '../CarouselSelect';
import Button from '../Button';
import {ReactComponent as Triangle} from './triangle.svg';

import './scheduler.css';

class Scheduler extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			address: '',
			name: '',
			email: '',
			phone: '',
			agent: '',
			dateOptions: [],
			timeOptions: [],
			datePosition: 0,
			timePosition: 0,
			visibleItems: 3,
			activeDate: '',
			activeTime: '',
			activePanel: 1
		}

		var today = new Date();

		let params = new URLSearchParams(window.location.search);
		let fakeTime = params.get("t");
		if (fakeTime) {
			today = new Date(fakeTime);
		}

		var todayReadable = today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate();
		var dateOptions = [];
		var activeDate;

		for (var i = 0; i < 14; i++) {

			var date = new Date(today.getTime() + i * 1000 * 60 * 60 * 24);
			var dayOfWeek;
			var month;

			if (today.getHours() > 17 && i == 0 || (today.getHours() == 17 && today.getMinutes() > 45 && i == 0)) {
				
			} else {
				var dateOption = {};

				if (!activeDate) { 
					activeDate = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate(); 
					this.state.activeDate = activeDate;
					dateOption.isActive = true;
				}

				switch (date.getDay()) {
					case 0:
						dayOfWeek = "Sunday";
						break;
					case 1:
						dayOfWeek = "Monday";
						break;
					case 2:
						dayOfWeek = "Tuesday";
						break;
					case 3:
						dayOfWeek = "Wednesday";
						break;
					case 4:
						dayOfWeek = "Thursday";
						break;
					case 5:
						dayOfWeek = "Friday";
						break;
					case 6:
						dayOfWeek = "Saturday";
						break;
					default:
						break;
				}

				switch (date.getMonth()) {
					case 0:
						month = "Jan"
						break;
					case 1:
						month = "Feb"
						break;
					case 2:
						month = "Mar"
						break;
					case 3:
						month = "Apr"
						break;
					case 4:
						month = "May"
						break;
					case 5:
						month = "June"
						break;
					case 6:
						month = "July"
						break;
					case 7:
						month = "Aug"
						break;
					case 8:
						month = "Sep"
						break;
					case 9:
						month = "Oct"
						break;
					case 10:
						month = "Nov"
						break;
					case 11:
						month = "Dec"
						break;
					default:
						break;
				}

				dateOption.mainText = date.getDate();
				dateOption.secondaryText = dayOfWeek;
				dateOption.subText = month;
				dateOption.key = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();

				dateOptions.push(dateOption);
			}
		}

		this.state.dateOptions = dateOptions;

		var timeSlots = this.generateTimeSlots(this.state.activeDate);

		this.state.timeOptions = timeSlots;

		var idxaddress = $("#IDX-detailsAddress").text();
		if (idxaddress) {
			idxaddress = normalizeWS(idxaddress);
			idxaddress = idxaddress.replace(" CO", " ");
			this.state.address = idxaddress;
		}

		function normalizeWS(s) {
		    s = s.match(/\S+/g);
		    return s ? s.join(' ') : '';
		}

		
	}

	generateTimeSlots = (day) => {

		var earliestTime = 9;
		const latestTime = 19;
		var sameDay = false;
		var today = new Date();
		var timeOptions = []; 

		if (day == (today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate())) {
			if (today.getHours() > 8) {
				earliestTime = today.getHours() + 1;
				sameDay = true;
			}
		} 

		for (var i = earliestTime; i <= latestTime; i++) {
			var hour = i;
			var modifier = 'AM';
			var minutes = '00';
			if (hour > 12) {
				hour -= 12;
				modifier = 'PM';
			} else if (hour === 12) {
				modifier = 'PM';
			}
			for (var j = 0; j < 4; j++) {
				switch (j) {
					case 1:
						minutes = '15';
						break;
					case 2:
						minutes = '30';
						break;
					case 3:
						minutes = '45';
						break;
					default:
						break;
				}

				if (sameDay && i == earliestTime && today.getMinutes() > parseInt(minutes)) {

				} else {

					timeOptions.push({
						mainText: hour + ":" + minutes,
						secondaryText: modifier,
						key: hour + ":" + minutes + " " + modifier
					})
				}
			}
		}

		return timeOptions;

	}

	

	onChangeAddress = (e) => {
		this.setState({address: e.target.value});
	}

	onChangeName = (e) => {
		this.setState({name: e.target.value});
	}

	onChangeEmail = (e) => {
		this.setState({email: e.target.value});
	}

	onChangePhone = (e) => {
		this.setState({phone: e.target.value});
	}

	onClickDate = (e) => {
		var selectedKey = (e.target.dataset.key);
		var dateOptions = this.state.dateOptions;
		dateOptions.forEach((el,index)=> {
			if(el.key == selectedKey) {
				el.isActive = true;
			} else {
				el.isActive = false;
			}
		});

		var timeSlots = this.generateTimeSlots(selectedKey);

		this.setState({
			dateOptions: dateOptions,
			activeDate: selectedKey,
			activeTime: '',
			timeOptions: timeSlots,
			timePosition: 0
		});
	}

	onClickTime = (e) => {
		var selectedKey = (e.target.dataset.key);
		var timeOptions = this.state.timeOptions;
		timeOptions.forEach((el,index)=> {
			if(el.key == selectedKey) {
				el.isActive = true;
			} else {
				el.isActive = false;
			}
		});

		this.setState({
			timeOptions: timeOptions,
			activeTime: selectedKey
		});
	}

	onClickNext = (e) => {
		e.preventDefault();
		if (this.state.address && this.state.activeDate && this.state.activeTime) {
			this.setState({activePanel: 2, error: null});
		} else {
			this.setState({error: "All fields are required"})
		}
	}

	onClickBack = (e) => {
		e.preventDefault();
		this.setState({activePanel: 1, error: null});
	}

	onAgentChange = (e) => {
		this.setState({agent: e.target.value})
	}

	getPosition = (e) => {
		const elements = $(e.target).closest(".carouselSelect").find(".CarouselSelectBox");
		if (elements.css("transform")) {
			const transform = parseInt(elements.css("transform").split(", ")[4]);
			const elementWidth = elements.outerWidth() + parseInt(elements.css("margin-right").replace("px","")) + parseInt(elements.css("margin-left").replace("px",""));
			const position = Math.round(transform / elementWidth);
			elements.removeAttr("style");
			return position;
		} else {
			return null;
		}
	}

	onClickRightDate = (e) => {
		const position = this.getPosition(e);
		const datePosition = position ? position : this.state.datePosition; 
		if (datePosition > (this.state.dateOptions.length * -1 + this.state.visibleItems)) {
			if (datePosition - this.state.visibleItems > (this.state.dateOptions.length * -1 + this.state.visibleItems)) {
				this.setState({datePosition: datePosition - this.state.visibleItems})
			} else {
				this.setState({datePosition: this.state.dateOptions.length * -1 + this.state.visibleItems})
			}
		}
	}

	onClickLeftDate = (e) => {
		const position = this.getPosition(e);
		const datePosition = position ? position : this.state.datePosition; 
		if (datePosition < 0) {
			if ((datePosition + this.state.visibleItems) < 0) {
				this.setState({datePosition: datePosition + this.state.visibleItems})
			} else {
				this.setState({datePosition: 0})
			}
		}
	}

	onClickRightTime = (e) => {
		const position = this.getPosition(e);
		const timePosition = position ? position : this.state.timePosition; 
		if (timePosition > (this.state.timeOptions.length * -1 + this.state.visibleItems)) {
			if (timePosition - this.state.visibleItems > (this.state.timeOptions.length * -1 + this.state.visibleItems)) {
				this.setState({timePosition: timePosition - this.state.visibleItems})
			} else {
				this.setState({timePosition: this.state.timeOptions.length * -1 + this.state.visibleItems})
			}
		}
	}

	onClickLeftTime = (e) => {
		const position = this.getPosition(e);
		const timePosition = position ? position : this.state.timePosition;
		if (timePosition < 0) {
			if ((timePosition + this.state.visibleItems) < 0) {
				this.setState({timePosition: timePosition + this.state.visibleItems})
			} else {
				this.setState({timePosition: 0})
			}
		}
	}

	onSubmitForm = (e) => {
		e.preventDefault();
		if (this.state.name && this.state.email && this.state.phone && this.state.agent) {
			this.setState({error: null});
			axios.post("https://api.backendless.com/D80765F6-DEB1-A1FD-FFC1-524B58C64D00/C709BC91-19A4-5D68-FF15-BA4D10921F00/data/tour_request", 
				{
					address: this.state.address,
					requested_date: this.state.activeDate,
					requested_time: this.state.activeTime,
					name: this.state.name,
					email: this.state.email,
					phone: this.state.phone,
					agent: this.state.agent === 'yes' ? true : false
				})
				.then(data => {
					this.setState({activePanel: 3})
				})
				.catch(error => {
					this.setState({error: "Error. Please try again later."})
				})

		} else {
			this.setState({error: "All fields are required"})
		}
	}

	onClickStartLooking = (e) => {
		this.props.onClickStartLooking(e);
	}

	render() {

		return (
			<form className="scheduler">
				<div className={this.state.activePanel === 1 ? "panel panel-1 active" : "panel panel-1"}>
					<h1><span className="green-text">START</span>&nbsp;<span>touring homes in an hour</span></h1>
					<p className="titleSubText">If you know what home you want to tour, enter the property address below. Don't know yet? <br />
          			<a href="#homes" className="p7STE-scroll-to-element button-pagetop" onClick={this.onClickStartLooking} >START looking at homes online</a> </p>
					<FormTitle line1="Step 1 of 2" line2="Pick Address &amp; Time" />
					<TextInput type="text" label="Enter Property Address" onChange={this.onChangeAddress} value={this.state.address} />
					<CarouselSelect onSelectItem={this.onClickDate} onClickLeft={this.onClickLeftDate} onClickRight={this.onClickRightDate} itemList={this.state.dateOptions} visibleItems={this.state.visibleItems} position={this.state.datePosition} />
					<CarouselSelect onSelectItem={this.onClickTime} onClickLeft={this.onClickLeftTime} onClickRight={this.onClickRightTime} itemList={this.state.timeOptions} visibleItems={this.state.visibleItems} position={this.state.timePosition} />
					<Button label="Next" onClick={this.onClickNext} />
					{this.state.error 
						? <div className="error"> {this.state.error} </div>
						: <div></div>
					}

				</div>
				<div className={this.state.activePanel === 2 ? "panel panel-2 active" : "panel panel-2"}>
					<div className="backButton" onClick={this.onClickBack} >
						<Triangle/>
						Back
					</div>
					<FormTitle line1="Step 2 of 2" line2="Tell us a little about yourself" />
					<TextInput type="text" label="First & Last Name" onChange={this.onChangeName} value={this.state.name} />
					<TextInput type="email" label="Email Address" onChange={this.onChangeEmail} value={this.state.email} />
					<TextInput type="phone" label="Phone Number" onChange={this.onChangePhone} value={this.state.phone} />
					<div className="agent-wrapper">
						<div className="agent-wrapper-label">Are you currently working with a real estate agent to help you buy a home?</div>
						<div className="radio-select-wrapper">
							<div className="radio-select"><input type="radio" name="agent" value="yes" checked={this.state.agent === "yes"} onChange={this.onAgentChange} /><label>yes</label></div>
							<div className="radio-select"><input type="radio" name="agent" value="no" checked={this.state.agent === "no"} onChange={this.onAgentChange} /><label>no</label></div>
						</div>
					</div>
					<Button label="Schedule Tour" onClick={this.onSubmitForm} />
					{this.state.error 
						? <div className="error"> {this.state.error} </div>
						: <div></div>
					}
				</div>
				<div className={this.state.activePanel === 3 ? "panel panel-3 active" : "panel panel-3"}>
					<div className="submittedText">We have received your request and will contact you shortly to confirm your home tour.</div>
				</div>
			</form>
		)
	}
}

export default Scheduler;